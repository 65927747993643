
export enum MoveTypes {
    WAITING,
    READY,


    ROCK,
    PAPER,
    SCISSORS
}

export function convertMoveToString(move: MoveTypes): string {
    switch(move) {
        case MoveTypes.ROCK:
            return "rock";
        case MoveTypes.PAPER:
            return "paper";
        case MoveTypes.SCISSORS:
            return "scissors";
        default:
            return "error"
    }
}

export function convertToMoveType(move: string): MoveTypes {
    if (move === "rock") {
        return MoveTypes.ROCK;
    } else if (move === "paper") {
        return MoveTypes.PAPER;
    } else if (move === "scissors") {
        return MoveTypes.SCISSORS;
    }
    return MoveTypes.WAITING;
}

export enum GameState {
    CONNECTING = 1,
    CONNECTING_OTHER_PLAYER,
    WAITING_BOTH_MOVES,
    WAITING_YOUR_MOVE,
    WAITING_THEIR_MOVE,
    WAITING_RESULT,
    SHOW_RESULTS
}

interface GameInfoProps {
    state: GameState,

    player1: MoveTypes,
    player2: MoveTypes
}

function GameInfo({state, player1, player2}: GameInfoProps) {

  switch(state) {

    case GameState.CONNECTING:
        return (
            <div className="flex flex-row">
                Connecting...
            </div>
        );

    case GameState.CONNECTING_OTHER_PLAYER:
        return (
            <div className="flex flex-row">
                Waiting for other Player...
            </div>
        );

    case GameState.WAITING_BOTH_MOVES:
        return (
            <div className="flex flex-row">
                Waiting Both Player Moves.
            </div>
        );



    case GameState.WAITING_YOUR_MOVE:
        return (
            <div className="flex flex-row">
                Other Player ready, waiting for local player.
            </div>
        );

    case GameState.WAITING_THEIR_MOVE:
        return (
            <div className="flex flex-row">
            Local Ready, waiting for Remote player.
            </div>
        );

    case GameState.WAITING_RESULT:
        return (
            <div className="flex flex-row">
                Waiting for results...
            </div>
        );

    case GameState.SHOW_RESULTS:
        return (
            <div className="flex flex-row">
                Game Finished, showing result:
                {player1}  -  {player2}
            </div>
        );

    default:
        return (
            <div className="flex flex-row">
                Error occurred, refresh.
            </div>
        );
  }
}

export default GameInfo;
