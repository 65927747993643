import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import GameHeader from '../components/GameHeader';
import GameInfo from '../components/GameInfo';
import MoveSelector from '../components/MoveSelector';
import { MoveTypes, GameState, convertToMoveType, convertMoveToString} from '../components/GameInfo';

import './Game.css';

interface PlayerMove {
  id: string;
  move: string;
}

class RpsGameMessage {
  type: string = ""

  id?: string
  players?: number

  results?: Array<PlayerMove>
}


function Game() {
  let { gameId } = useParams() as {gameId: string};

  const [localPlayerId, setLocalPlayerId] = useState<string>("");
  const [playerCount, setPlayerCount] = useState<number>(0);

  const [localPlayerMove, setLocalPlayerMove] = useState<MoveTypes>(MoveTypes.WAITING);
  const [remotePlayerMove, setRemotePlayerMove] = useState<MoveTypes>(MoveTypes.WAITING);

  const [localWinCount, setLocalWinCount] = useState<number>(0);
  const [tieCount, setTieCount] = useState<number>(0);
  const [remoteWinCount, setRemoteWinCount] = useState<number>(0);

  const [socketUrl] = useState(`wss://game.rps.me/${gameId}`);
  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
    socketUrl, {
      shouldReconnect: (closeEvent) => true,
    }
  );

  function sendMoveToServer(move: MoveTypes, state: GameState) {

    // If the player sent a move, reset the results cause they want to 
    // start the next game
    if (state === GameState.SHOW_RESULTS) {
      setRemotePlayerMove(MoveTypes.WAITING);
    }

    setLocalPlayerMove(move);

    sendJsonMessage({
      "type": "move",
      "move": convertMoveToString(move)
    })
  }

  useEffect(() => {
    let msg = Object.assign(new RpsGameMessage(), lastJsonMessage);
    console.log("Received Message: ", msg);

    switch(msg.type) {
      case "joined": // Sent by server indicating we successfully joined a game
        if (msg.id) {
          console.log(`Server allocated us the name: ${msg.id}`);
          setLocalPlayerId(msg.id);
        }
        if (msg.players && msg.players > 1) {
          console.log(`Player Count is ready to play a game: ${msg.players}`);
          setPlayerCount(msg.players);
        }
        break;
      case "completed":
        console.log("Game completed");

        let localMove  = MoveTypes.WAITING;
        let remoteMove = MoveTypes.WAITING;

        for(let player of msg.results??[]) {
          if (player.id === localPlayerId) {
            localMove = convertToMoveType(player.move);
          } else {
            remoteMove = convertToMoveType(player.move);
          }
        }

        if (localMove === MoveTypes.WAITING || remoteMove === MoveTypes.WAITING) {
          return;
        }

        if (localPlayerMove !== localMove) {
          setLocalPlayerMove(localMove);
        }
        setRemotePlayerMove(remoteMove);

        const localWinner = 
          (localMove === MoveTypes.ROCK && remoteMove === MoveTypes.SCISSORS) ||
          (localMove === MoveTypes.PAPER && remoteMove === MoveTypes.ROCK) ||
          (localMove === MoveTypes.SCISSORS && remoteMove === MoveTypes.PAPER);

        if (localMove === remoteMove) {
          setTieCount(d => d + 1);
        } else if (localWinner) {
          setLocalWinCount(d => d + 1);
        } else {
          setRemoteWinCount(d => d + 1);
        }
        
        break;
      case "connected":
        if (msg.id) {
          console.log("Player connected: ", msg.id);
        }
        break;
      case "disconnected":
        if (msg.id) {
          console.log("Player disconnected: ", msg.id);

        }
        break;
      case "ready":
        if (msg.id) {
          console.log("Player move ready: ", msg.id);
          if (msg.id !== localPlayerId) {
            if (remotePlayerMove !== MoveTypes.WAITING && localPlayerMove !== MoveTypes.WAITING) {
              setLocalPlayerMove(MoveTypes.WAITING);
            }
            setRemotePlayerMove(MoveTypes.READY);
          }
        }
        break;
    }

    // TODO: switch to useEffectEvent when it is no longer experimental api
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastJsonMessage]);

  let gameState = GameState.CONNECTING;

  if (readyState === ReadyState.OPEN) {
    gameState = GameState.CONNECTING_OTHER_PLAYER;
    
    if (playerCount >= 2) {
      console.log("Local: ", localPlayerMove, " Remote: ", remotePlayerMove);
      if (localPlayerMove === MoveTypes.WAITING && remotePlayerMove === MoveTypes.WAITING) {
        gameState = GameState.WAITING_BOTH_MOVES;
      } else if (localPlayerMove !== MoveTypes.WAITING && remotePlayerMove === MoveTypes.WAITING) {
        gameState = GameState.WAITING_THEIR_MOVE;
      } else if (localPlayerMove === MoveTypes.WAITING && remotePlayerMove === MoveTypes.READY) {
        gameState = GameState.WAITING_YOUR_MOVE;
      } else if (localPlayerMove !== MoveTypes.WAITING && remotePlayerMove === MoveTypes.READY) {
        gameState = GameState.WAITING_RESULT;
      } else {
        gameState = GameState.SHOW_RESULTS;
      }
    }
  }


  return (
    <div className="flex flex-col h-screen justify-between">
      <GameHeader gameId={gameId} localWinCount={localWinCount} tieCount={tieCount} remoteWinCount={remoteWinCount}/>
      <GameInfo state={gameState} player1={localPlayerMove} player2={remotePlayerMove} />
      <MoveSelector playerMove={localPlayerMove} gameState={gameState} sendMove={sendMoveToServer} />
    </div>
  );
}

export default Game;
