

interface GameHeaderProps {
    gameId: string

    localWinCount: number
    tieCount: number
    remoteWinCount: number
}

function GameHeader({gameId, localWinCount, tieCount, remoteWinCount}: GameHeaderProps) {

    return (
        <header className='flex flex-row'>
            {gameId}: {localWinCount} - {tieCount} - {remoteWinCount}
        </header>
    );
}

export default GameHeader;
