
import { ReactComponent as IconRock } from './icon_rock.svg';
import { ReactComponent as IconPaper } from './icon_paper.svg';
import { ReactComponent as IconScissors } from './icon_scissors.svg';
import { GameState, MoveTypes } from './GameInfo';

interface MoveSelectorProps {
    playerMove: MoveTypes,
    gameState: GameState
    sendMove: (move: MoveTypes, state: GameState) => void
}

function MoveSelector({playerMove, gameState, sendMove}: MoveSelectorProps) {

    const baseButton   = 'h-full mx-4';
    const moveSelected = 'bg-white border rounded-lg'

    return (
        <div className='flex flex-row max-h-32 h-32 justify-center p-2 bg-slate-400 border-t-4 border-slate-800  rounder-t-lg'>
            <button className={`${baseButton} ${(playerMove === MoveTypes.ROCK ? moveSelected : '')}`} 
                    onClick={() => sendMove(MoveTypes.ROCK, gameState)}>
                <IconRock className='h-full'></IconRock>
            </button>
            <button className={`${baseButton} ${(playerMove === MoveTypes.PAPER ? moveSelected : '')}`}
                    onClick={() => sendMove(MoveTypes.PAPER, gameState)}>
                <IconPaper className='h-full'></IconPaper>
            </button>
            <button className={`${baseButton} ${(playerMove === MoveTypes.SCISSORS ? moveSelected : '')}`}
                    onClick={() => sendMove(MoveTypes.SCISSORS, gameState)}>
                <IconScissors className='h-full'></IconScissors>
            </button>
        </div>
    );
}

export default MoveSelector;
